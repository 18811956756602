@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

:root {
  --text-color: #285a89;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --github: #333333;
  --website: #1890ff;
  --gmail: #db4437;
  --white: #ffffff;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}


.MuiAppBar-root {

  background-color: #406976 !important;
  margin-top: 0 !important;
  box-shadow: 0px 0px 30px rgba(227,228, 237, 0.37); /* Slight shadow for depth */
  backdrop-filter: blur(30px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* ------chart------ */
.chart-container {
  flex: 1;
}

/* .dashboard-home-button{
  background-color: #10619b; 
  color: #000000 !important; 
  border: 2px; 
  padding: 10px 20px; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: all 0.3s ease-in-out;
} */
/* .dashboard-home-button:hover {
  box-shadow: 0 0 20px #10619b;
  } */
  
.green {
  color: green;
}

.red {
  color: red;
}


.recharts-legend-icon {
  display: none;
}
.recharts-legend-item-text {
  display: none;
}

.data-grid-cell {
  border-right: 1px solid #070000;
}

.data-grid-cell:last-child {
  border-right: none;
}
