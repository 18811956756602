 /* .chart-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
   }
  
  .chart-component {
    flex: 1; 
   }  */

.chart-page {
display: flex;
width: 100%;
height: 100vh; 
}

.chart-component {
flex: 1; 
width: 100%;
}
